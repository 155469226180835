<template>
  <div>

    <div class="h4  sp-mb-2">{{ $t("on_boarding.bank_fields.title") }}</div>
    <p>{{ $t("on_boarding.bank_fields.intro") }}</p>

    <hr/>

    <label class="form-label" for="bankCountry">{{ $t('form_fields.country') }}</label>
    <d-select id="bankCountry" v-model="bankCountry"  @change="showRequiredFields" :class="{ 'is-invalid': hasError('bankCountry') }" :disabled="allDisabled">
      <option value="" disabled>{{ $t('form_fields.choose_a_country') }}</option>
      <template v-for="(item, index) in countries">
        <option :value="item.value" :key="index">
          {{ item.text }}
        </option>
      </template>
    </d-select>
    <d-form-invalid-feedback v-if="hasError('bankCountry')" :force-show="true">
      <span v-html="getError('bankCountry')"></span>
    </d-form-invalid-feedback>

    <div class="sp-mt-3">
      <hr />
    </div>

    <div class="h5 sp-mt-3 sp-mb-0">{{ $t("on_boarding.bank_fields.required_bank_info") }}</div>

    <d-alert v-if="!countrySelected" class="sp-mt-2" theme="light" show>
      <i class="material-icons u-color-secondary--800  sp-mr-1" style="font-size: 16px;">help</i>
      {{ $t("on_boarding.bank_fields.choose_a_country") }}
    </d-alert>


    <div class="l-form  sp-mt-2" v-else>

      <div class="l-form__item"  v-if="ibanRequired">
        <label class="form-label" for="iban">{{ $t('form_fields.iban') }}</label>
        <d-input id="iban" v-model="iban" :class="{ 'is-invalid': hasError('iban') }" :disabled="allDisabled"/>
        <d-form-invalid-feedback v-if="hasError('iban')" :force-show="true">
          <span v-html="getError('iban')"></span>
        </d-form-invalid-feedback>
      </div>


      <div class="l-form__item"  v-if="swiftRequired">
        <label class="form-label" for="swift">{{ $t('form_fields.swift') }}</label>
        <d-input id="swift" v-model="swift" :class="{ 'is-invalid': hasError('swift') }" :disabled="allDisabled"/>
        <d-form-invalid-feedback v-if="hasError('swift')" :force-show="true">
          <span v-html="getError('swift')"></span>
        </d-form-invalid-feedback>
      </div>


      <div class="l-form__item"  v-if="abaNumRequired">
        <label class="form-label" for="abaNum">{{ $t('form_fields.abaNum') }}</label>
        <d-input id="abaNum" v-model="abaNum" :class="{ 'is-invalid': hasError('abaNum') }" :disabled="allDisabled"/>
        <d-form-invalid-feedback v-if="hasError('abaNum')" :force-show="true">
          <span v-html="getError('abaNum')"></span>
        </d-form-invalid-feedback>
      </div>


      <div class="l-form__item"  v-if="bsbNumRequired">
        <label class="form-label" for="bsbNum">{{ $t('form_fields.bsbNum') }}</label>
        <d-input id="bsbNum" v-model="bsbNum" :class="{ 'is-invalid': hasError('bsbNum') }" :disabled="allDisabled"/>
        <d-form-invalid-feedback v-if="hasError('bsbNum')" :force-show="true">
          <span v-html="getError('bsbNum')"></span>
        </d-form-invalid-feedback>
      </div>


      <div class="l-form__item"  v-if="acctNumRequired">
        <label class="form-label" for="acctNum">{{ $t('form_fields.acctNum') }}</label>
        <d-input id="acctNum" v-model="acctNum" :class="{ 'is-invalid': hasError('acctNum') }" :disabled="allDisabled"/>
        <d-form-invalid-feedback v-if="hasError('acctNum')" :force-show="true">
          <span v-html="getError('acctNum')"></span>
        </d-form-invalid-feedback>
      </div>

    </div>

    <hr/>

    <div class="h5 sp-mt-3 sp-mb-0">{{ $t("on_boarding.bank_fields.bank_statement") }}</div>

    <div class="sp-mtb-2">
      <div class="form-label">{{ $t('form_fields.bank_statement') }}</div>
      <div class="custom-file  form__file-uploader">
        <input type="file" class="custom-file-input" id="customFile"
               @change="previewFile"
               accept="image/png, image/jpeg, image/gif, application/pdf">
        <label class="custom-file-label" for="customFile">
          <span v-html="fileLabel"></span>
        </label>
      </div>

      <d-form-invalid-feedback v-if="hasError('file')" :force-show="true">
        <span v-html="getError('file')"></span>
      </d-form-invalid-feedback>
    </div>

    <div v-if="hasError('others')" class="sp-mtb-2">
      <d-form-invalid-feedback  :force-show="true">
        <span v-html="getError('others')"></span>
      </d-form-invalid-feedback>
    </div>

    <div class="u-divide">
      <router-link v-if="!firstTime" :to="{name: 'onBoarding.initial', params: {providerId: providerId}}" class="btn  btn-light">
        {{ $t('form_fields.to_overview') }}
      </router-link>
      <div v-else></div>

      <loading v-if="isLoading" />
      <d-button v-else
        @click="submit"
        :disabled="disabled || !countrySelected"
      >
        {{ $t('form_fields.save_continue') }}
      </d-button>
    </div>



  </div>
</template>

<script>
import {mapBankFields, mapRegistrationFields} from "~/store";
import formSettings, {rules, bankAccountFields as bankAccount} from "~/onBoardingSettings"
import Loading from "@/components/loading";

export default {
  name: "BankFields",
  components: {Loading},

  props: {
    providerId: {
      type: String,
      required: true,
    }
  },

  mounted() {
    this.resetFieldsRequiredField()

    this.bankCountry = this.$store.state.providerStore.formData.bankCountry
    this.iban = this.$store.state.providerStore.formData.bankCountry
    this.swift = this.$store.state.providerStore.formData.swift

    if(this.bankCountry !== '') this.showRequiredFields(this.bankCountry)
  },

  data: () => ({
    errors: [],
    disabled: false,
    isLoading: false,

    requiredFields: [],
    allDisabled: false,
    countries: formSettings.businessCountries,
    countrySelected: false,

    ibanRequired: false,
    swiftRequired: false,
    abaNumRequired: false,
    bsbNumRequired: false,
    acctNumRequired: false,

    rules: {
      country: rules.country.rules,
      file: rules.file.rules,

      swift: rules.swift.rules,
      iban: rules.iban.rules,
    },

    optionRules: {
      swift: rules.swift.rules,
      iban: rules.iban.rules,
      // bsbNum: rules.stringCheck.rules,
      abaNum: rules.stringCheck.rules,
      acctNum: rules.stringCheck.rules,
    },

  }),

  computed: {
    ...mapBankFields([
      'formData.file',
      'formData.bankCountry',
      'formData.bankName',
      'formData.bankAddress',
      'formData.bankZipCode',
      'formData.bankCity',
      'formData.iban',
      'formData.swift',
      'formData.abaNum',
      'formData.bsbNum',
      'formData.bankNum',
      'formData.acctNum',
      'formData.transitNum',
      'formData',
    ]),
    ...mapRegistrationFields([
      'firstTime',
    ]),
    fileLabel: function () {
      if(this.isset(this.file)) {
        return this.file.name + '<small>(' + this.number_format(this.file.size / 1000, 2, '.', '') + 'kB)</small>';
      }
      return this.$t('form_fields.search');
    },
  },

  methods: {

    hasError: function (field) {
      return this.errors.find(error => error.field === field);
    },

    getError: function (field) {
      return this.errors.find(error => error.field === field).messages.join('<br/>');
    },

    resetFieldsRequiredField() {
      this.requiredFields = [];
      this.bankAddressRequired = false;
      this.bankZipCodeRequired = false;
      this.bankCityRequired = false;
      this.ibanRequired = false;
      this.swiftRequired = false;
      this.abaNumRequired = false;
      this.bsbNumRequired = false;
      this.bankNumRequired = false;
      this.acctNumRequired = false;
      this.transitNumRequired = false;
    },

    checkVerificationProces() {

    },

    showRequiredFields(evt) {

      // Show the header
      this.countrySelected = true;

      // Reset the required field when an other country is selected
      this.resetFieldsRequiredField()

      // Only proceed when the available counties are selected
      if(bankAccount[evt] == undefined || bankAccount[evt].requiredFields == undefined )  return

      // Add the required fileds for the bankaccount based on the selected country
      this.requiredFields = bankAccount[evt].requiredFields
      this.requiredFields.forEach(item => {
        this[item + "Required"] = true
      })
    },

    previewFile (event) {
      if(event.target.files.length <= 0) return;
      this.file = event.target.files[0];
    },

    submit() {

      this.errors = [];
      let valid = true;

      // Run default validation
      for(let attribute in this.rules) {

        let rulesValid = this.runValidation(this[attribute], this.rules[attribute]);
        if(!rulesValid[0]) {
          valid = false;
          this.errors.push({
            field: attribute,
            messages: rulesValid[1]
          });
        }
      }

      // Add the required fields for all countries
      let variables = {
        input: {
          providerID: this.providerId,
          file: this.formData.file,
          bankCountry: this.formData.bankCountry
        }
      }

      // Loop over the required fields
      this.requiredFields.forEach(item => {

        // Append to variables set
        variables.input[item] = this.formData[item]

        // Run validation for the required option fields if isset
        const optionRules = this.optionRules[item];
        if(!this.isset(optionRules)) return;

        let rulesValid = this.runValidation(this[item], optionRules);
        if(!rulesValid[0]) {
          valid = false;
          this.errors.push({
            field: item,
            messages: rulesValid[1]
          });
        }
      });

      // If valid form submit all data to the server
      if (!valid) {
        this.errors.push({
          field: 'others',
          messages: [this.$t('form_validation.contains_errors')]
        });
        return
      }

      this.disabled = true

      this.isLoading = true
      this.$apollo.mutate({
        mutation: require('~/graphql/onBoarding/RegisterBankAccount.gql'), variables, fetchPolicy: 'no-cache'
      }).then(data => {

        new Promise((resolve, reject) => {
          if(data.data.registerBankAccount.success) resolve();
          else reject(data.data.registerBankAccount);
        })
        .then(() => {
          this.$router.push({name: 'onBoarding.step5', params: {providerId: this.providerId}})
        }).catch((error) => {

          this.disabled = false
          console.error(error)
          this.errors.push({
            field: 'others',
            messages: [error.message]
          });
        })

      }).catch((error) => {
        console.error(error)
        this.errors.push({
          field: 'others',
          messages: [this.removeDefaultGraphQLPrefix(error.message)]
        });
      }).then(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
